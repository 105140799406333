






















import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry'
import Vue from 'vue';
import { filters } from '@/filters/Filters';
import { Actions, ISkillFullData, ISkillSimState, Mutations } from '@/views/SkillSimStore';
import IIconInfo from '@/models/util/IIconInfo';
import SkillProvider from '@/api/SkillProvider';
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import SkillSimSkillTooltip from './SkillSimSkillTooltip.vue';
// @ts-ignore
import { Portal } from '@linusborg/vue-simple-portal';
import { createPopper, Instance } from '@popperjs/core';
import { uuidv4 } from '@/util/Utils';

interface IData {
    tooltipStyle: any;
    guid: string;
    isHover: boolean;
    popper: Instance|null;
}

export default Vue.extend({
    components: {
        SpriteIcon,
        SkillSimSkillTooltip,
        Portal,
    },
    props: {
        skillTreeEntry: {
            type: Object as () => ISkillTreeEntry,
        },
        index: {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            tooltipStyle: {},
            guid: uuidv4(),
            isHover: false,
            popper: null,
        };
    },
    computed: {
        skillSimState(): ISkillSimState {
            return (this.$store.state as any).skillsim;
        },
        embedded(): boolean {
            return this.skillSimState.embedded;
        },
        skills(): Map<number, ISkillFullData> {
            return this.skillSimState.skills;
        },
        skill(): ISkillFullData {
            return this.skills.get(this.getAwakeningMapping ? this.getAwakeningMapping : this.skillTreeEntry.skillId)!;
        },
        getAwakeningMapping(): number {
            return this.skillSimState.awakeningMods[this.skillTreeEntry.skillId] || 0;
        },
        maxRegularLevel(): number {
            return this.skill.skill._MaxLevel - this.skill.skill._SPMaxLevel;
        },
        maxLevel(): number {
            return this.skill.skill._MaxLevel;
        },
        hasTechLevels(): boolean {
            return this.maxRegularLevel !== this.maxLevel;
        },
        entryClass(): any {
            const ret: any = [];
            
            ret.push(filters.skillType(this.skill.skill._SkillType));

            if (this.availableLevel <= 0) {
                ret.push('unavailable');
            }

            if (!this.isTech && this.level > this.availableLevel) {
                ret.push('invalid');
            }
            
            if (this.level === 0) {
                ret.push('zero');
            }

            return ret;
        },
        level(): number {
            return this.skillSimState.skillBuild[this.index];
        },
        skillIconInfo(): IIconInfo {
            const iconIndex = this.skill.skill._IconImageIndex;
            const buffIcon = this.skill.skill._BuffIconImageIndex;
            const debuffIcon = this.skill.skill._DeBuffIconImageIndex;
            let ret;
            if (iconIndex === 0) {
                if (buffIcon > 0) {
                    return SkillProvider.buffIcon(buffIcon)!;
                } else if (debuffIcon > 0) {
                    return SkillProvider.buffIcon(debuffIcon)!;
                } else {
                    return SkillProvider.icon(iconIndex);
                }
            } else {
                return SkillProvider.icon(iconIndex);
            }
        },
        isTech(): boolean {
            return false;
        },
        levelClass(): string {
            if (this.level === 0) {
                return 'zero';
            }

            if (this.isTech) {
                return 'tech';
            }

            if (this.level > this.availableLevel) {
                return 'invalid';
            }

            return '';
        },
        availableLevels(): number[] {
            return this.skillSimState.maxAvailableLevel;
        },
        availableLevel(): number {
            return this.availableLevels[this.index];
        },
        canIncrement(): boolean {
            return this.level < this.availableLevel;
        },
        canDecrement(): boolean {
            let minLevel = 0;
            if (this.skillTreeEntry.isDefaultLearned) {
                minLevel = 1;
            }

            return this.level > minLevel;
        },
        tooltipId(): string {
            return `tooltip-${this.guid}`;
        },
    },
    mounted() {
        setTimeout(() => {
            const base = this.$refs.base as HTMLDivElement;
            const tooltip = document.querySelector('#' + this.tooltipId) as HTMLDivElement;
            
            const treeBase = document.querySelector('.sim-body');

            this.popper = createPopper(base, tooltip, {
                placement: 'right-start',
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: document,
                            padding: 10,
                        },
                    },
                ],
            });
        }, 500);
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
        }
    },
    methods: {
        increment(evt: MouseEvent) {
            if (evt.shiftKey) {
                this.$store.dispatch(`skillsim/${Actions.SetSkillBuildLevel}`, {index: this.index, level: this.availableLevel});
            } else if (this.level < this.maxLevel) {
                if (!this.canIncrement) {
                    return;
                }

                this.$store.dispatch(`skillsim/${Actions.IncrementSkillLevel}`, this.index);
            }
        },
        decrement(evt: MouseEvent) {
            // cannot unlearn autolearned skills
            let minLevel = 0;
            if (this.skillTreeEntry.isDefaultLearned) {
                minLevel = 1;
            }

            if (evt.shiftKey) {
                this.$store.dispatch(`skillsim/${Actions.SetSkillBuildLevel}`, {index: this.index, level: minLevel});
            } else if (this.canDecrement) {
                this.$store.dispatch(`skillsim/${Actions.DecrementSkillLevel}`, this.index);
            }
        },
        hover() {
            this.isHover = true;
            this.$nextTick().then(() => {
                if (this.popper) {
                    this.popper.update();
                }
            });
        }
    }
})
