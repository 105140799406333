




































import Vue from 'vue';
import SkillProvider from "@/api/SkillProvider";
import ResponsiveCardList from "@/components/util/ResponsiveCardList.vue";
import ResponsiveCardListEntry from "@/components/util/ResponsiveCardListEntry.vue";
import SkillStubLink  from "@/components/skill/SkillStubLink.vue";
import ISkill from '../../models/skills/ISkill';

interface IData {
    query: string;
    loading: boolean;
    skills: ISkill[];
}

export default Vue.extend({
    components: {
        ResponsiveCardList,
        ResponsiveCardListEntry,
        SkillStubLink,
    },
    props: {
        "initialQuery": {
            type: String as () => string,
        },
    },
    data(): IData {
        return {
            query: this.initialQuery,
            loading: false,
            skills: [],
        };
    },
    watch: {
        initialQuery() {
            this.query = this.initialQuery;
            this.searchSkill();
        }
    },
    computed: {
        valid(): boolean {
            return !!this.query && !this.loading;
        },
    },
    mounted() {
        this.searchSkill();
    },
    methods: {
        async searchSkill() {
            if (!this.valid) {
                return;
            }

            this.loading = true;
            const asN = Number(this.query);
            if (!isNaN(asN)) {
                try {
                    const sk = await SkillProvider.getSkill(asN)
                    if (sk) {
                        this.skills = [sk];
                    } else {
                        this.skills = [];
                    }
                } catch (e) {
                    this.skills = [];
                }
            } else {
                this.skills = await SkillProvider.searchSkillsByName(this.query);
            }
            this.loading = false;

            this.$emit("query", this.query);
        }
    }
});
