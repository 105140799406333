














































import Vue from 'vue';
import TableProvider from '@/api/TableProvider';
import IStateEffectTableRow, { EffectOutputType } from '@/models/skills/raw/IStateEffectTableRow';

interface IData {
    loading: boolean;
    effect: IStateEffectTableRow|null;
    error: string;
}

export default Vue.extend({
    props: {
        stateEffectId: {
            type: Number as () => number,
        },
    },
    watch: {
        stateEffectId() {
            this.load();
        }
    },
    data(): IData {
        return {
            loading: false,
            effect: null,
            error: '',
        };
    },
    computed: {
        effectOutputType(): string {
            if (this.effect) {
                return EffectOutputType[this.effect._EffectOutputType];
            }
            
            return '';
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                this.error = '';
                this.effect = await TableProvider.getTableRow<IStateEffectTableRow>('stateeffecttable', this.stateEffectId, undefined, {
                    fileresolve: ['_EffectSkinFileName', '_EffectAniFileName', '_EffectActFileName'],
                });
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.error = String(error);
            }
        }
    }
})
