







































































import SkillProvider from '@/api/SkillProvider';
import TableProvider from '@/api/TableProvider';
import ISkilLTreeTableRow from '@/models/skills/raw/ISkillTreeTableRow';
import Vue from 'vue'
import SkillStubLink from './SkillStubLink.vue';

interface IData {
    autolearnSkills: number[];
    awakeningConditionSkillId: number;
    awakenedSkillId: number;
    
    awakensOtherSkillIds: {skillId: number, resultId: number}[];
    awakendByOtherSkillIds: {skillId: number, awakenSkillId: number}[];
    autolearnedBySkillIds: number[];
    
    loading: boolean;
    error: string;
}

export default Vue.extend({
    components: { SkillStubLink },
    props: {
        skillId: {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            autolearnSkills: [],
            awakeningConditionSkillId: 0,
            awakenedSkillId: 0,
            
            awakensOtherSkillIds: [],
            awakendByOtherSkillIds: [],
            autolearnedBySkillIds: [],
            
            loading: false,
            error: '',
        };
    },
    watch: {
        skillId() {
            this.load();
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            if (!this.skillId) {
                return;
            }

            try {
                this.loading = true;
                this.error = '';
                this.autolearnSkills = [];
                this.awakeningConditionSkillId = 0;
                this.awakenedSkillId = 0;
                this.awakensOtherSkillIds = [];
                this.awakendByOtherSkillIds = [];
                this.autolearnedBySkillIds = [];
                // get skill tree data
                let rows = await TableProvider.getTableRowsMatching<ISkilLTreeTableRow>('skilltreetable', '_SkillTableID', 'eq', this.skillId);
                if (rows.length) {
                    const r = rows[0];
                    this.autolearnSkills = r._ChangeSkill.split(';').map(Number).filter((v) => !isNaN(v) && v > 0);
                    this.awakenedSkillId = r._SubSkillTableID,
                    this.awakeningConditionSkillId = r._SkillConditionID;
                }
                
                rows = await TableProvider.getTableRowsMatching<ISkilLTreeTableRow>('skilltreetable', '_SkillConditionID', 'eq', this.skillId);
                if (rows.length) {
                    this.awakensOtherSkillIds = rows.map((r) => ({ skillId: r._SkillTableID, resultId: r._SubSkillTableID })).filter((v) => v.skillId && v.resultId);
                }
                
                rows = await TableProvider.getTableRowsMatching<ISkilLTreeTableRow>('skilltreetable', '_SubSkillTableID', 'eq', this.skillId);
                if (rows.length) {
                    this.awakendByOtherSkillIds = rows.map((r) => ({ skillId: r._SkillTableID, awakenSkillId: r._SkillConditionID })).filter((v) => v.skillId && v.awakenSkillId);
                }
                
                rows = await TableProvider.getTableRowsMatching<ISkilLTreeTableRow>('skilltreetable', '_ChangeSkill', 'in', this.skillId);
                if (rows.length) {
                    this.autolearnedBySkillIds = rows.filter((r) => r._ChangeSkill.split(';').map(Number).includes(this.skillId)).map((r) => r._SkillTableID).filter((v) => !!v);
                }
            } catch (e) {
                console.error(e);
                this.error = String(e);
            } finally {
                this.loading = false;
            }
        },
    },
})
