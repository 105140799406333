import Vuex, { Module } from 'vuex';
import Vue from 'vue';
import { IState } from '@/store';
import IJob from '@/models/jobs/IJob';
import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry';
import ISkill from '@/models/skills/ISkill';
import ISkillLevel from '@/models/skills/ISkillLevel';
import SkillProvider from '@/api/SkillProvider';
import JobProvider from '@/api/JobProvider';
import ISkillTableRow from '@/models/skills/raw/ISkillTableRow';
import ISkillLevelTableRow from '@/models/skills/raw/ISkillLevelTableRow';
import { Dictionary } from 'vue-router/types/router';
import TableProvider from '@/api/TableProvider';
import ITableRow from '@/models/table/ITableRow';

export interface ISkillFullData {
    skill: ISkillTableRow,
    levels: ISkillLevelTableRow[],
};

export interface ISkillTree {
    first: ISkillTreeEntry[],
    second: ISkillTreeEntry[],
    third: ISkillTreeEntry[],
    all: ISkillTreeEntry[],
};

export interface ISp {
    total: number;
    first: number;
    second: number;
    third: number;
};

export interface IAwakeningMods {
    [key: number]: number|undefined;
}

export interface ISkillSimState {
    jobs: IJob[];
    job: IJob|null;
    skillTree: ISkillTree;
    skills: Map<number, ISkillFullData>;
    skillBuild: number[];
    maxAvailableLevel: number[];
    levelCap: number;
    maxSp: ISp;
    awakeningMods: IAwakeningMods;
    error: string;
    embedded: boolean;
}

export enum SimState {
    LOADING_JOBS,
    PICK_JOB,
    LOADING_SKILLS,
    SIM_SKILLS,
}

export const Actions = {
    Reset: 'reset',
    Init: 'init',
    SetJob: 'setJob',
    IncrementSkillLevel: 'incrementSkillLevel',
    DecrementSkillLevel: 'decrementSkillLevel',
    SetSkillBuildLevel: 'setSkillBuildLevel',
    ResetBuild: 'resetBuild',
    UpdateAvailableLevel: 'updateAvailableLevel',
    SetAvailableLevel: 'setAvailableLevel',
    AddAwakeningMod: 'addAwakeningMod',
    SetAwakeningMod: 'setAwakeningMod',
    DeleteAwakeningMod: 'deleteAwakeningMod',
    ClearAwakeningMod: 'clearAwakeningMod',
};

export const Getters = {
    IsLoading: 'isLoading',
    SimState: 'simState',
};

export const Mutations = {
    SetJobs: 'setJobs',
    SetActiveJob: 'setActiveJob',
    SetSkills: 'setSkills',
    SetSkillTreeFirst: 'setFirstSkillTree',
    SetSkillTreeSecond: 'setSecondSkillTree',
    SetSkillTreeThird: 'setThirdSkillTree',
    SetSkillTreeAll: 'setThirdSkillAll',
    SetSkillBuildLevel: 'setSkillBuildLevel',
    UpdateSkillBuildLevel: 'updateSkillBuildLevel',
    SetAvailableLevel: 'setAvailableLevel',
    UpdateAvailableLevel: 'updateAvailableLevel',
    AddAwakeningMod: 'addAwakeningMod',
    SetAwakeningMod: 'setAwakeningMod',
    DeleteAwakeningMod: 'deleteAwakeningMod',
    ClearAwakeningMod: 'clearAwakeningMod',
    SetError: 'setError',
};

export const BUILD_LENGTH = 96;

export default function createSkillSimStore(): Module<ISkillSimState, IState> {
    return {
        namespaced: true,
        state: {
            jobs: [],
            job: null,
            skillTree: {
                first: [],
                second: [],
                third: [],
                all: [],
            },
            skills: new Map(),
            skillBuild: new Array(BUILD_LENGTH).fill(0),
            maxAvailableLevel: new Array(BUILD_LENGTH).fill(0),
            levelCap: 0,
            maxSp: {
                first: 0,
                second: 0,
                third: 0,
                total: 0,
            },
            awakeningMods: {},
            error: '',
            embedded: false,
        },
        actions: {
            [Actions.Reset]({ dispatch, commit, getters, rootGetters }) {
                commit(Mutations.SetError, '');
                commit(Mutations.SetJobs, []);
                commit(Mutations.SetActiveJob, null);
                commit(Mutations.SetSkills, new Map());
                commit(Mutations.SetSkillTreeFirst, []);
                commit(Mutations.SetSkillTreeSecond, []);
                commit(Mutations.SetSkillTreeThird, []);
                commit(Mutations.SetSkillBuildLevel, new Array(BUILD_LENGTH).fill(0));
                commit(Mutations.SetAvailableLevel, new Array(BUILD_LENGTH).fill(0));

                dispatch(Actions.Init);
            },
            async [Actions.Init]({ dispatch, commit, getters, rootGetters }, query?: Dictionary<string>) {
                try {
                    commit(Mutations.SetError, '');
                    // Get level cap
                    const levelCapRow = await TableProvider.getTableRow<any>('globalweighttable', 26);
                    const levelCap = levelCapRow._Value;
                    commit('setLevelCap', levelCap);

                    const res = await JobProvider.getAllJobs();
                    // Set up parent relationships
                    for (const job of res) {
                        if (job.parentJobId > 0) {
                            job.parentJob = res.find((v) => v.id === job.parentJobId) || null;
                        }
                    }

                    const jobs = res.filter((v) => v.jobNumber == 2);
                    commit(Mutations.SetJobs, jobs);

                    if (query && query.j) {
                        const jobId = Number(query.j);
                        if (!isNaN(jobId)) {
                            const job = jobs.find((v) => v.id === jobId);
                            if (job) {
                                dispatch(Actions.SetJob, job);
                            }
                        }
                    }
                } catch (e) {
                     commit(Mutations.SetError, `Initialization failure`);
                    throw new Error(`Failed to init: ${e}`);
                }
            },
            async [Actions.SetJob]({ dispatch, commit, getters, rootGetters, state }, job: IJob) {
                if (job.jobNumber !== 2) {
                    throw new Error(`You can only use SetJob with a final spec class`);
                }
                try {
                    commit(Mutations.SetError, '');
                    commit(Mutations.SetActiveJob, job);
                    const secondJob = job.parentJob!;
                    const firstJob = secondJob.parentJob!;

                    const [first, second, third] = await Promise.allSettled([
                        SkillProvider.getSkillTreeInfo(firstJob.id),
                        SkillProvider.getSkillTreeInfo(secondJob.id),
                        SkillProvider.getSkillTreeInfo(job.id)
                    ]);

                    if (first.status === 'rejected') {
                        throw new Error(`Failed to load base class tree: ${first.reason}`);
                    }

                    if (second.status === 'rejected') {
                        throw new Error(`Failed to load first class tree: ${second.reason}`);
                    }

                    if (third.status === 'rejected') {
                        throw new Error(`Failed to load second class tree: ${third.reason}`);
                    }

                    const firstSkillTree = first.value;
                    const secondSkillTree = second.value;
                    const thirdSkillTree = third.value;
                    // Fun times get all the skills
                    const allTrees = [...firstSkillTree, ...secondSkillTree, ...thirdSkillTree];

                    const [defaultCreateRow, ...rest] = await TableProvider.getTableRowsMatching<any>('defaultcreatetable', '_ClassID', 'eq', firstJob.id);
                    if (defaultCreateRow) {
                        for (let i = 1; i <= 10; ++i) {
                            const key = `_DefaultSkill${i}`;
                            const skillId = defaultCreateRow[key] as number;
                            if (skillId > 0) {
                                const treeEntry = allTrees.find((v) => v.skillId === skillId);
                                if (treeEntry) {
                                    treeEntry.isDefaultLearned = true;
                                }
                            }
                        }
                    }

                    // Load playerleveltable for the class
                    const prefix = (job.id - 1) * 100;
                    const rowIds = new Array(state.levelCap).fill(prefix).map((v, i) => v + i + 1);
                    const levelRows = await TableProvider.getTableRows<{_SkillPoint: number, id: number, ownerTable: string}>('playerleveltable', rowIds, undefined, {
                        select: ['_SkillPoint'],
                    });

                    const totalSp = Object.values(levelRows).reduce((pv, v) => pv + v._SkillPoint, 0);
                    
                    // lol /jobs api doesn't provide that
                    const rawJobTableRow = await TableProvider.getTableRow<any>('jobtable', job.id, undefined, {
                        select: ['_MaxSPJob0', '_MaxSPJob1', '_MaxSPJob2'],
                    });

                    const maxSp: ISp = {
                        total: totalSp,
                        first: Math.floor(rawJobTableRow._MaxSPJob0 * totalSp),
                        second: Math.floor(rawJobTableRow._MaxSPJob1 * totalSp),
                        third: Math.floor(rawJobTableRow._MaxSPJob2 * totalSp),
                    }

                    commit('setMaxSp', maxSp);

                    const skillIds = [...new Set<number>([...allTrees.map((v) => v.skillId), ...allTrees.filter((v) => v.awakenedSkillId > 0).map((v) => v.awakenedSkillId)])];
                    const skills = await SkillProvider.getBulkSkills(skillIds);

                    const skillLevels = await SkillProvider.getBulkSkillLevelsMulti(skillIds);
                    const skillMap = new Map<number, ISkillFullData>();
                    
                    for (const skill of skills) {
                        const entry: ISkillFullData = {
                            skill,
                            levels: skillLevels.filter((v) => v._SkillIndex === skill.id),
                        };

                        skillMap.set(skill.id, entry);
                        
                        // Also update the skilltree tab
                        const treeItem = allTrees.find((v) => v.skillId === skill.id);
                        if (treeItem && treeItem.tab === -1) {
                            const needJob = skill._NeedJob;
                            let checkJob: IJob|null = job;
                            do {
                                if (checkJob.id === needJob) {
                                    treeItem.tab = checkJob.jobNumber + 1;
                                    break;
                                }
                                
                                checkJob = checkJob.parentJob;
                            } while (checkJob)
                        }
                    }

                    commit(Mutations.SetSkills, skillMap);
                    commit(Mutations.SetSkillTreeFirst, firstSkillTree);
                    commit(Mutations.SetSkillTreeSecond, secondSkillTree);
                    commit(Mutations.SetSkillTreeThird, thirdSkillTree);
                    commit(Mutations.SetSkillTreeAll);
                    commit(Mutations.SetSkillBuildLevel, new Array(BUILD_LENGTH).fill(0));
                    commit(Mutations.SetAvailableLevel, new Array(BUILD_LENGTH).fill(0));

                    // Set the automatically learned skills
                    for (const skillTreeEntry of allTrees) {
                        if (skillTreeEntry.isDefaultLearned) {
                            commit(Mutations.UpdateSkillBuildLevel, { index: skillTreeEntry.index, level: 1 });
                            console.log(`Skill ${skillTreeEntry.skillId} autolearned`);
                        }
                    }
                } catch (e) {
                    commit(Mutations.SetError, `Job load failure`);
                    throw new Error(`Failed to load class data: ${e}`);
                }
            },
            [Actions.IncrementSkillLevel]({ commit, state }, index: number) {
                const level = state.skillBuild[index] + 1;
                commit(Mutations.UpdateSkillBuildLevel, { index, level });
            },
            [Actions.DecrementSkillLevel]({ commit, state }, index: number) {
                const level = state.skillBuild[index] - 1;
                commit(Mutations.UpdateSkillBuildLevel, { index, level });
            },
            [Actions.SetSkillBuildLevel]({ commit }, val: { index: number; level: number; }) {
                commit(Mutations.UpdateSkillBuildLevel, val);
            },
            [Actions.ResetBuild]({ commit }) {
                commit(Mutations.SetSkillBuildLevel, new Array(BUILD_LENGTH).fill(0));
                commit(Mutations.SetAvailableLevel, new Array(BUILD_LENGTH).fill(0));
            },
            [Actions.UpdateAvailableLevel]({ commit }, val: { index: number; level: number; }) {
                commit(Mutations.UpdateAvailableLevel, val);
            },
            [Actions.SetAvailableLevel]({ commit }, val: number[]) {
                commit(Mutations.SetAvailableLevel, val);
            },
            [Actions.AddAwakeningMod]({ commit }, val: [number, number]) {
                commit(Mutations.AddAwakeningMod, val);
            },
            [Actions.SetAwakeningMod]({ commit }, val: IAwakeningMods) {
                commit(Mutations.SetAwakeningMod, val);
            },
            [Actions.DeleteAwakeningMod]({ commit }, val: number) {
                commit(Mutations.DeleteAwakeningMod, val);
            },
            [Actions.ClearAwakeningMod]({ commit }) {
                commit(Mutations.ClearAwakeningMod);
            },
        },
        getters: {
            [Getters.SimState](state): SimState {
                if (!state.jobs.length) {
                    return SimState.LOADING_JOBS;
                }

                if (!state.job) {
                    return SimState.PICK_JOB;
                }

                if (state.skills.size === 0 || !state.skillTree.first.length || !state.skillTree.second.length || !state.skillTree.third.length) {
                    return SimState.LOADING_SKILLS;
                }

                return SimState.SIM_SKILLS;
            },
            [Getters.IsLoading](state, getters, rootState, rootGetters): boolean {
                const simState: SimState = getters[Getters.SimState];
                if (simState === SimState.LOADING_JOBS || simState === SimState.LOADING_SKILLS) {
                    return true;
                }

                return false;
            },
        },
        mutations: {
            [Mutations.SetJobs](state, jobs: IJob[]) {
                state.jobs.splice(0, state.jobs.length, ...jobs);
            },
            [Mutations.SetActiveJob](state, job: IJob|null) {
                state.job = job;
            },
            [Mutations.SetSkills](state, skills: Map<number, ISkillFullData>) {
                state.skills = skills;
            },
            [Mutations.SetSkillTreeFirst](state, tree: ISkillTreeEntry[]) {
                state.skillTree.first.splice(0, state.skillTree.first.length, ...tree);
            },
            [Mutations.SetSkillTreeSecond](state, tree: ISkillTreeEntry[]) {
                state.skillTree.second.splice(0, state.skillTree.second.length, ...tree);
            },
            [Mutations.SetSkillTreeThird](state, tree: ISkillTreeEntry[]) {
                state.skillTree.third.splice(0, state.skillTree.third.length, ...tree);
            },
            [Mutations.SetSkillTreeAll](state) {
                state.skillTree.all.splice(0, state.skillTree.all.length, ...[...state.skillTree.first, ...state.skillTree.second, ...state.skillTree.third]);
            },
            [Mutations.UpdateSkillBuildLevel](state: ISkillSimState, level: { index: number; level: number; } ) {
                Vue.set(state.skillBuild, level.index, level.level);
            },
            [Mutations.SetSkillBuildLevel](state: ISkillSimState, availableLevels: number[]) {
                state.skillBuild.splice(0, state.skillBuild.length, ...availableLevels);
            },
            [Mutations.UpdateAvailableLevel](state: ISkillSimState, level: { index: number, level: number; }) {
                Vue.set(state.maxAvailableLevel, level.index, level.level);
            },
            [Mutations.SetAvailableLevel](state: ISkillSimState, availableLevels: number[]) {
                state.maxAvailableLevel.splice(0, state.maxAvailableLevel.length, ...availableLevels);
            },
            [Mutations.AddAwakeningMod](state: ISkillSimState, val: [number, number]) {
                Vue.set(state.awakeningMods, val[0], val[1]);
            },
            [Mutations.SetAwakeningMod](state: ISkillSimState, val: IAwakeningMods) {
                for (const key in state.awakeningMods) {
                    Vue.set(state.awakeningMods, key, 0);
                }
                
                for (const key in val) {
                    Vue.set(state.awakeningMods, key, val[key]);
                }
            },
            [Mutations.DeleteAwakeningMod](state: ISkillSimState, skillId: number) {
                Vue.set(state.awakeningMods, skillId, 0);
            },
            [Mutations.ClearAwakeningMod](state: ISkillSimState) {
                for (const key in state.awakeningMods) {
                    Vue.set(state.awakeningMods, key, 0);
                }
            },
            [Mutations.SetError](state: ISkillSimState, err: string) {
                state.error = err;
            },
            // Internal
            setLevelCap(state: ISkillSimState, cap: number) {
                state.levelCap = cap;
            },
            setMaxSp(state: ISkillSimState, sp: ISp) {
                state.maxSp.first = sp.first;
                state.maxSp.second = sp.second;
                state.maxSp.third = sp.third;
                state.maxSp.total = sp.total;
            },
            setEmbedded(state: ISkillSimState, embedded: boolean) {
                state.embedded = embedded;
            },
        },
    }
};

if (module.hot) {
    module.hot.decline();
}
