


































import IJob from '@/models/jobs/IJob'
import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry';
import { Actions, ISkillFullData, ISkillSimState } from '@/views/SkillSimStore';
import Vue from 'vue'
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import SkillSimTreeItem from './SkillSimTreeItem.vue';

export default Vue.extend({
    components: {
        SpriteIcon,
        SkillSimTreeItem,
    },
    props: {
        job: {
            type: Object as () => IJob,
        },
        awakening: {
            type: Boolean as () => boolean,
        },
        tree: {
            type: Number as () => 1|2|3,
        },
    },
    computed: {
        skillSimState(): ISkillSimState {
            return (this.$store.state as any).skillsim;
        },
        embedded(): boolean {
            return this.skillSimState.embedded;
        },
        skillBuildOffset(): number {
            return 24 * this.job.jobNumber + (!!this.awakening ? 24 : 0);
        },
        skillBuildBaseOffset(): number {
            return 24 * this.job.jobNumber;
        },
        skillTreeKey(): 'first'|'second'|'third' {
            switch (this.job.jobNumber) {
                case 0: return 'first';
                case 1: return 'second';
                case 2: return 'third';
                default: return 'third';
            }
        },
        skillTree(): ISkillTreeEntry[] {
            return this.skillSimState.skillTree[this.skillTreeKey].filter((v) => v.isAwakened === !!this.awakening);
        },
        skillTreeWithAwakening(): ISkillTreeEntry[] {
            return this.skillSimState.skillTree[this.skillTreeKey];
        },
        skillBuild(): number[] {
            return this.skillSimState.skillBuild;
        },
        skills(): Map<number, ISkillFullData> {
            return this.skillSimState.skills;
        },
        slots(): (ISkillTreeEntry|null)[] {
            const ret = [];
            for (let i = 0; i < (4*6); ++i) {
                ret[i] = this.skillTree.find((s) => s.index === i) || null;
            }

            return ret;
        },
        maxSp(): number {
            return this.skillSimState.maxSp[this.skillTreeKey];
        },
        treeUsedSp(): number {
            let sum = 0;
            for (const entry of this.skillTreeWithAwakening) {
                if (entry) {
                    const skill = this.skills.get(entry.skillId);
                    if (skill) {
                        
                        const index = entry.index + this.skillBuildBaseOffset + (entry.isAwakened ? 24 : 0);
                        const level = this.skillBuild[index];
                        const maxRegularLevel = skill.skill._MaxLevel - skill.skill._SPMaxLevel;
                        const add = skill.levels.filter((v) => v._SkillLevel <= level && v._ApplyType === 0 && v._SkillLevel <= maxRegularLevel).reduce((pv, v) => pv + v._NeedSkillPoint, 0);
                        sum += add;
                    }
                }
            }

            return sum;
        },
    },
    methods: {
        reset() {
            const base = (this.tree - 1) * 24;
            for (let i  = 0; i < 24; ++i) {
                let minLevel = 0;
                const skillTreeEntry = this.skillTree.find((v) => v.index === i && v.tab === this.tree);
                if (skillTreeEntry && skillTreeEntry.isDefaultLearned) {
                    minLevel = 1;
                }

                this.$store.dispatch(`skillsim/${Actions.SetSkillBuildLevel}`, {index: base + i, level: minLevel});
            }
        },
    },
})
