import { render, staticRenderFns } from "./SkillSimSkillSubTree.vue?vue&type=template&id=3b3428ea&scoped=true&"
import script from "./SkillSimSkillSubTree.vue?vue&type=script&lang=ts&"
export * from "./SkillSimSkillSubTree.vue?vue&type=script&lang=ts&"
import style0 from "./SkillSimSkillSubTree.vue?vue&type=style&index=0&id=3b3428ea&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3428ea",
  null
  
)

export default component.exports